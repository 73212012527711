// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bm_kz";
export var caseStudyBackground__bgColor = "bm_kp";
export var caseStudyBackground__lineColor = "bm_kq";
export var caseStudyHead = "bm_kC";
export var caseStudyHead__imageAbsolute = "bm_kJ";
export var caseStudyHead__imageWrapper = "bm_kn";
export var caseStudyProjectsSection = "bm_kB";
export var caseStudyQuote__bgRing = "bm_kw";
export var caseStudyTechSection = "bm_kH";
export var caseStudy__bgDark = "bm_km";
export var caseStudy__bgLight = "bm_kl";